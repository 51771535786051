import { Button, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useState } from 'react';
import { PDFReader } from 'reactjs-pdf-reader';
import { getFullVelocityUrl } from '../../../Helper';
import { accountApi } from '../../../api/AccountApi';
import { LogOnViewModel } from '../../../model/AccountModel';
import { Modal } from '../../common';
import styles from './agreement.module.scss';

//Avoid creating object style inline, since increases reconciliations
const fullVelocityUrl = getFullVelocityUrl();
const urlPathWithFile = `${fullVelocityUrl}Content/Files/EULA_3.8.6.115.pdf`;
const style: React.CSSProperties = {
	maxHeight: 530,
	maxWidth: 752,
	overflow: 'auto',
};

type Props = {
	logOnViewModel: LogOnViewModel;
	setIsUserNotificationAccepted?: (userNotificationState: boolean) => void;
};

const Agreement: React.FC<Props> = ({ logOnViewModel, setIsUserNotificationAccepted }) => {
	const isUserNotification = logOnViewModel.LogOnInfo.ShowUserNotification;

	const [isRead, setRead] = useState(isUserNotification);
	const [isAccepted, setAccepted] = useState(false);
	const [visible, setVisible] = useState(true);

	const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 50;
		if (!isRead && bottom <= e.currentTarget.clientHeight) {
			setRead(true);
		}
	};

	const handleRefuseAgreement = () => {
		if (isUserNotification) {
			setIsUserNotificationAccepted(true);
			setVisible(false);
		} else {
			const url = `${fullVelocityUrl}Account/LogOff`;
			window.location.href = url;
		}
	};

	const handleChangeRadioButtons = (e: RadioChangeEvent) => {
		setAccepted(e.target.value);
	};

	const handleAcceptAgreement = () => {
		if (isUserNotification) {
			setIsUserNotificationAccepted(false);
			setVisible(false);
		} else {
			if (isAccepted) {
				accountApi.updateAgreementByOperator().then(res => {
					if (res) {
						const url = `${location.origin}/${logOnViewModel.RedirectTo}`;
						window.location.href = url;
					} else {
						handleRefuseAgreement();
					}
				});
			} else {
				handleRefuseAgreement();
			}
		}
	};

	let container: React.ReactElement;
	const buttons: React.ReactNode[] = [
		<Button key='OK' type='primary' onClick={() => handleAcceptAgreement()}>
			{isUserNotification ? _('Accept') : _('OK')}
		</Button>,
		<Button key='cancel' onClick={() => handleRefuseAgreement()}>
			{_('Cancel')}
		</Button>,
	];

	if (!isUserNotification) {
		container = (
			<>
				<div className={styles.pdf} id={'pdfViewer'} onScroll={handleScroll}>
					<PDFReader width={700} showAllPage={true} url={urlPathWithFile} />
				</div>
				<div className={styles.acceptance}>
					<Radio.Group name='radiogroup' defaultValue={false} onChange={handleChangeRadioButtons}>
						<Radio value={true} disabled={!isRead}>
							{_('IAgreeToTheTermsInTheLicenseAgreement')}.
						</Radio>
						<Radio value={false}>{_('IDoNotAcceptTheLicenseAgreement')}.</Radio>
					</Radio.Group>
					<Button key='save' type='primary' onClick={() => window.open(urlPathWithFile, '_blank')}>
						{_('PrintAgreement')}
					</Button>
				</div>
			</>
		);
	} else {
		//these values are getting from top application , MVC input hidden fields => (Account/LogOn.cshtml)
		const userNotification = document.getElementById('userNotificationFromMVC') as HTMLInputElement;

		container = (
			<div
				style={style}
				dangerouslySetInnerHTML={{
					__html: userNotification.value,
				}}
			/>
		);
	}

	const title: string = !isUserNotification
		? _('PleaseReadAndAgreeToTheFollowingLicenseAgreementBeforeContinuing')
		: _('PleaseReadAndAgreeToTheFollowingBeforeContinuing');

	return (
		visible && (
			<Modal
				keyboard={false}
				onClickOk={() => null}
				maskClosable={false}
				closable={!isUserNotification}
				onCancel={() => handleRefuseAgreement()}
				title={title}
				visible={true}
				footer={buttons}
				customZoomClass={isUserNotification ? '50vh' : styles.withModalZoom}
				width='800px'>
				<div className={styles.container}>{container}</div>
			</Modal>
		)
	);
};

export { Agreement };

